<template>
  <v-row class="ma-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-card flat tile width="100%">
        <v-card-text class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" lg="6" class="d-flex justify-center align-baseline pb-6">
              <v-card :loading="pending" width="100%" :height="pending ? $vuetify.breakpoint.height / 10 : $vuetify.breakpoint.height / 1.65">
                <v-card-title>Available items</v-card-title>
                <v-card-text v-if="!pending"
                             :style="`height: ${$vuetify.breakpoint.height / 1.93}px; overflow-y: scroll; overflow-x: hidden;`">
                  <draggable v-model="sections"
                             :group="{ name: 'availableSections', pull: 'clone', put: false }">
                    <v-card
                      min-width="75%"
                      max-width="100%"
                      min-height="3.5rem"
                      class="my-2 lbm_hover"
                      v-for="(element, index) in sections"
                      :key="index"
                    >
                      <v-card-text>{{ element.name }}</v-card-text>
                    </v-card>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6" class="d-flex ma-0 pb-6">
              <v-card :loading="pending" width="100%"
                      :min-height="pending ? $vuetify.breakpoint.height / 10 : $vuetify.breakpoint.height / 2.4">
                <v-card-title>Footer items</v-card-title>
                <v-card-text v-if="!pending">
                  <draggable @change="checkDoubles"
                             v-model="footer" group="availableSections">
                    <v-card
                      min-width="75%"
                      max-width="100%"
                      min-height="3.5rem"
                      class="my-2 lbm_hover"
                      v-for="(element, index) in footer"
                      :key="index"
                    >
                      <v-card-text>
                        <v-row class="ma-0 pa-0 pr-6 pl-6 d-flex align-center">
                          <v-col cols="11">
                            {{ element.name }}
                          </v-col>
                          <v-col cols="1" class="pa-0 d-flex justify-space-between">
                            <v-btn @click="openEdit(element)" color="primary" dark fab x-small>
                              <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn class="ml-2" @click="deleteElement(element)" color="error" dark fab x-small>
                              <v-icon x-small>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </draggable>
                </v-card-text>
                <v-card-actions v-if="!pending" class="pb-6 pr-4">
                  <v-spacer/>
                  <v-btn @click="saveFooter" color="primary" dark>
                    Save Footer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog :width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.width / 4 : $vuetify.breakpoint.width / 1.3" v-model="show" :hide-overlay="$vuetify.breakpoint.lgAndUp">
      <v-card tile>
        <v-card-title>
          Edit footer item
          <v-spacer></v-spacer>
          <v-btn @click="closeEdit" fab small dark color="primary">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-item-group>
            <v-item>
              <v-text-field label="Please, write title for footer item"
                            v-model="item.name"
                            placeholder="Edit footer item name" type="text"></v-text-field>
            </v-item>
            <v-item>
              <v-text-field label="Please, write route for footer item"
                            v-model="item.route"
                            placeholder="Edit footer item route" type="text"></v-text-field>
            </v-item>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="save" color="primary" tile dark>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'Footer',
  components: {
    draggable
  },
  data () {
    return {
      show: false,
      sections: [],
      footer: [],
      item: {},
      edited: {},
      id: null,
      saveWasClicked: false,
      pending: false
    }
  },
  mounted () {
    this.getFooter()
    this.$root.$on('delete-footer-item', (id) => {
      this.$http.delete(`api/v2/auth/footer/${id}`)
        .then(() => {
          this.getFooter()
          this.$store.dispatch('setConfirmation', { show: false, action: null })
        })
    })
  },
  methods: {
    getFooter () {
      this.pending = true
      this.$http.get('api/v2/auth/footer')
        .then(response => {
          this.footer = response.data.footer
          this.sections = response.data.sections
          this.pending = false
        })
        .catch(() => {
          this.pending = false
        })
    },
    openEdit (item) {
      this.saveWasClicked = false
      this.item = item
      this.edited = Object.assign({}, item)
      this.id = this.footer.indexOf(item)
      this.show = true
    },
    closeEdit () {
      if (this.saveWasClicked) {
        this.show = false
        this.item = {}
      } else {
        this.footer[this.id] = this.edited
        this.show = false
        this.item = {}
      }
    },
    save () {
      this.saveWasClicked = true
      this.closeEdit()
    },
    saveFooter () {
      this.$http.post('api/v2/auth/footer', {
        items: this.footer
      })
    },
    checkDoubles () {
      this.footer = [...new Set(this.footer)]
    },
    deleteElement (item) {
      this.$store.dispatch('setConfirmation', { show: true, action: 'delete-footer-item', id: item.id })
    }
  }
}
</script>

<style scoped>

</style>
